const config = new Map([
    ['/en', {
        name: 'English',
        icon: 'icon-usa-flag',
        href: '/en'
    }],
    ['/ru', {
        name: 'Русский',
        icon: 'icon-rus-flag',
        href: '/ru'
    }]
])

function languageSwitcher() {
    const langSw = {
        selectedContainer: document.querySelector(".lang-sw__select"),
        optionsContainer: document.querySelector('.lang-sw__options-container'),

        init() {
            const urlParam = window.location.pathname;
            const currLang = [...config.keys()].includes(urlParam) ? urlParam : '/en';

            const selectedIcon = document.createElement('i');
            selectedIcon.classList.add('lang-sw__icon', config.get(currLang).icon);

            const selectedText = document.createElement('span');
            selectedText.classList.add('lang-sw__selected-text');
            selectedText.textContent = config.get(currLang).name;

            this.selectedContainer.appendChild(selectedIcon);
            this.selectedContainer.appendChild(selectedText);

            this.setOptions(currLang);

            document.addEventListener('click', () => {
                if (this.optionsContainer.classList.contains('lang-sw__options-container_opened')) {
                    this.closeDropdown();
                    return;
                }
            })

            this.selectedContainer.addEventListener('click', (e) => {
                e.stopPropagation();

                if ([...config.keys()].length < 2) {
                    return;
                }

                if (this.optionsContainer.classList.contains('lang-sw__options-container_opened')) {
                    this.closeDropdown();
                    return;
                }

                this.optionsContainer.classList.add('lang-sw__options-container_opened');
            })
        },

        setOptions(currLang) {
            for (const key of [...config.keys()]) {
                if (key === currLang) {
                    continue;
                }

                const obj = config.get(key);
                const option = document.createElement('a');
                option.classList.add('lang-sw__option');
                option.setAttribute('href', obj.href);

                const icon = document.createElement('i');
                icon.classList.add('lang-sw__icon', obj.icon);
                const name = document.createElement('span');
                name.classList.add('lang-sw__name');
                name.textContent = obj.name;

                option.appendChild(icon);
                option.appendChild(name);

                this.optionsContainer.appendChild(option);
            }
        },

        closeDropdown() {
            this.optionsContainer.classList.remove('lang-sw__options-container_opened');
        },
    };

    langSw.init();
}