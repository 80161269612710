const currConfig = {
    BTC: 'BTC',
    ATT: 'ETH',
    STBL: 'USDT',
    RUB: 'RUB',
    EUR: 'EUR',
    USD: 'USD'
}

const MAX_INPUT_LENGTH = 8;

function exchangeCalculator() {
    const exchangeCalc = {
        timeout: null,

        input: document.querySelector('.exchange__input'),
        output: document.querySelector('.exchange__output'),

        selectIn: document.querySelector('.curr-sw_in .curr-sw__select'),
        dropdownIn: document.querySelector('.curr-sw_in .curr-sw__options-container'),

        selectOut: document.querySelector('.curr-sw_out .curr-sw__select'),
        dropdownOut: document.querySelector('.curr-sw_out .curr-sw__options-container'),

        exchangeBtn: document.querySelector('.exchange .exchange__circle-btn'),

        init() {
            const optIdIn = document.querySelector('.curr-sw_in .curr-sw__selected').getAttribute('data-curr');
            const optIdOut = document.querySelector('.curr-sw_out .curr-sw__selected').getAttribute('data-curr');

            this.markOption(this.dropdownIn.children, optIdIn);
            this.markOption(this.dropdownOut.children, optIdOut);

            this.input.value = '0';
            
            document.addEventListener('click', () => {
                this.closeDropdown(this.selectIn, this.dropdownIn);
                this.closeDropdown(this.selectOut, this.dropdownOut);
            })

            this.exchangeBtn.addEventListener('click', () => {
                const selected = this.selectOut.querySelector('.curr-sw__selected');

                this.setOption(selected, this.dropdownIn, this.selectIn, this.selectOut, this.dropdownOut);
            })

            this.input.addEventListener('input', () => {
                clearTimeout(this.timeout);

                this.timeout = setTimeout(() => {
                    this.getCurrencyExchange();
                }, 500);
            })

            this.input.addEventListener('keypress', (e) => {
                if (this.input.value === '0' && e.code !== 'Period' && e.code !== 'Comma') {
                    this.input.value = ''
                    this.input.setSelectionRange(0, 0);
                    this.input.focus();
                }

                if (this.input.value.length > MAX_INPUT_LENGTH) {
                    e.preventDefault();
                    return
                };
            })

            this.input.addEventListener('keyup', () => {
                if (+this.input.value < 0 || this.input.value.length === 0 || isNaN(this.input.value)) {
                    this.input.value = '0'
                    this.input.setSelectionRange(0, 0);
                    this.input.focus();
                }
            })

            this.selectIn.addEventListener('click', (e) => {
                e.stopPropagation();

                this.toggleState(this.selectIn, this.dropdownIn)
                this.closeDropdown(this.selectOut, this.dropdownOut);
            })

            this.selectOut.addEventListener('click', (e) => {
                e.stopPropagation();

                this.toggleState(this.selectOut, this.dropdownOut);
                this.closeDropdown(this.selectIn, this.dropdownIn);
            })

            this.dropdownIn.addEventListener('click', (e) => {
                this.setOption(e.target, e.currentTarget, this.selectIn, this.selectOut, this.dropdownOut);
            })

            this.dropdownOut.addEventListener('click', (e) => {
                this.setOption(e.target, e.currentTarget, this.selectOut, this.selectIn, this.dropdownIn);
            })
        },

        toggleState(select, dropdown) {
            select.classList.toggle('curr-sw__select_active');
            dropdown.classList.toggle('curr-sw__options-container_opened');
        },

        closeDropdown(select, dropdown) {
            select.classList.remove('curr-sw__select_active');
            dropdown.classList.remove('curr-sw__options-container_opened');
        },

        setOption(target, list, triggeredSelect, oppositeSelect, oppositeList) {
            const prevOptId = triggeredSelect.querySelector('.curr-sw__selected').getAttribute('data-curr');
            const nextOptId = target.getAttribute('data-curr');

            if (prevOptId === nextOptId) {
                return;
            }

            clearTimeout(this.timeout);

            this.resetList(list.children);

            const opposSelected = oppositeSelect.querySelector('.curr-sw__selected')
            const opposOptId = opposSelected.getAttribute('data-curr');

            if (nextOptId === opposOptId) {
                this.resetList(oppositeList.children);

                opposSelected.setAttribute('data-curr', prevOptId);
                opposSelected.textContent = currConfig[prevOptId];

                this.markOption(oppositeList.children, prevOptId);
            }

            const curSelected = triggeredSelect.querySelector('.curr-sw__selected');

            curSelected.setAttribute('data-curr', nextOptId);
            curSelected.textContent = currConfig[nextOptId];

            this.markOption(list.children, nextOptId);
            this.getCurrencyExchange();
        },

        getCurrencyExchange() {
            const amount = this.input.value;

            if (!amount || amount <= 0) {
                this.output.textContent = 0;
                return;
            }

            const base = this.selectIn.querySelector('.curr-sw__selected').getAttribute('data-curr');
            const symbol = this.selectOut.querySelector('.curr-sw__selected').getAttribute('data-curr');

            const requestURL = `https://api.exchangerate.host/latest?source=crypto&base=${base}&amount=${amount}&symbols=${symbol}`;
            var request = new XMLHttpRequest();
            request.open('GET', requestURL);
            request.responseType = 'json';
            request.send();

            request.onload = () => {
                if (request.status === 200) {
                    const response = request.response;
                    this.output.textContent = response.rates[Object.keys(response.rates)[0]];
                }

                if (!request.response.success){
                    this.setFakeData();
                }
            }
        },

        resetList(list) {
            for (let opt of list) {
                opt.classList.remove('curr-sw__option_selected');
            }
        },

        markOption(list, curr) {

            for (let opt of list) {
                if (opt.getAttribute('data-curr') === curr) {
                    opt.classList.add('curr-sw__option_selected');
                }
            }
        },

        setFakeData() {
            const optIdIn = document.querySelector('.curr-sw_in .curr-sw__selected').getAttribute('data-curr');
            const optIdOut = document.querySelector('.curr-sw_out .curr-sw__selected').getAttribute('data-curr');

            const k = fakeCurrData[optIdIn][optIdOut];

            this.output.textContent = k * this.input.value;
        }
    };

    exchangeCalc.init();
}